import React, { useEffect, useRef } from "react";
import sanitizeHtml from "sanitize-html";
import "./SafeContent.less";
import { useModal } from "scripts/hooks";
import { useTranslation } from "react-i18next";
import FormatText from "scripts/utils/FormatText";

type SafeContentProps = {
  content: string;
  context: ContextState;
};

const replaceTagNameElement = (el: Element, newTagName: string) => {
  if (el && el.tagName.toLowerCase() !== newTagName) {
    const newEl = document.createElement(newTagName);
    newEl.innerHTML = sanitizeHtml(el.innerHTML);
    el.parentNode.replaceChild(newEl, el);
  }
};

const replaceHeadings = (
  root: HTMLElement,
  headings: string[] = ["h3", "h4", "h5", "h6"]
): HTMLElement => {
  if (!root) {
    return root;
  }
  let next = -1;
  let replaceWith: string[] = null;
  const findFirstMissingHeading = (heading: string) =>
    root.querySelectorAll(heading).length <= 0;
  headings.forEach((heading, index) => {
    if (!replaceWith && findFirstMissingHeading) {
      replaceWith = headings.slice(index);
    } else {
      const foundedHeadings = root.querySelectorAll(heading);
      if (foundedHeadings.length > 0) {
        if (next < replaceWith.length) {
          next = next + 1;
        }
        foundedHeadings.forEach((e) =>
          replaceTagNameElement(e, replaceWith[next])
        );
      }
    }
  });
  return root;
};

const SafeContent = ({ content, context }: SafeContentProps) => {
  const ref = useRef<HTMLDivElement>();
  const { triggerModal, dismissModal } = useModal();
  const { t } = useTranslation(["LegalsComponent"]);

  useEffect(() => {
    if (ref.current) {
      const formatText = new FormatText(
        context.appContext,
        context.hideheader,
        context.serviceEndpoints.identityService,
        context.serviceEndpoints.wwwRootDomain,
        (href: string) => {
          triggerModal({
            title: t("x_link_header"),
            body: t("x_link_body"),
            closeText: t("x_link_cancel_button_text"),
            confirmText: t("x_link_ok_button_text"),
            onConfirm: async () => {
              window.location.href = href;
            },
            onClose: async () => dismissModal(),
            role: "alertdialog"
          });
        }
      );

      ref.current.appendChild(replaceHeadings(formatText.Apply(content)));
    }
  }, [ref, content]);

  return <div ref={ref} className="safe-content" />;
};

export default SafeContent;
