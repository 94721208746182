import React from "react";
import { Route, Switch } from "react-router-dom";
import { History, LocationState } from "history";
import { connect } from "react-redux";
import { hot } from "react-hot-loader";
import { AuthActions, ContextActions, ModalActions } from "./actions";
import userManager from "./services/userManager";
import { Dispatch, bindActionCreators } from "redux";
import { defaultRoutes, journeyRoutes } from "./routes";
import { RouterState } from "connected-react-router";
import { Modal, Page } from "@lego/lego-account-ui";
import i18n, { initializeI18N, t } from "scripts/utils/i18n";
import { SafeContent } from "./components";
import { trackPage } from "./utils/tracking";


export namespace App {
  export type StateProps = {
    router: RouterState<LocationState>;
    context: ContextState;
    auth: AuthState;
    modal: ModalState;
  };
  export type OwnProps = {
    history: History;
  };
  export type ActionProps = {
    authActions: ThunkActionsDispatch<AuthActions>;
    contextactions: ThunkActionsDispatch<ContextActions>;
    modalActions: ThunkActionsDispatch<ModalActions>;
  };
  export type Props = StateProps & ActionProps & OwnProps;
}

class App extends React.Component<App.Props> {
  constructor (props: App.Props) {
    super(props);
    // The listener`
    this.props.history.listen(() => trackPage(props.context));
  }

  async componentDidMount() {
    const { context, authActions } = this.props
    const userManagerInstance = userManager.instance();
    userManagerInstance.events.addUserLoaded(
      authActions.UserRefreshed
    );
    initializeI18N(context.culture);
    await i18n.loadNamespaces("Generic");
  }

  renderModalBody = (): React.ReactNode => {
    const { modal } = this.props
    if (!modal.body) {
      return undefined;
    } else
      if (modal?.richBody === true) {
        return <SafeContent content={(modal.body as string)} context={this.props.context} />
      } else {
        return modal.body
      }
  }

  renderVisual = (visual: React.ReactNode | string) => {
    if (!visual) {
      return undefined;
    }
    if (typeof visual === 'string')
      return <img src={visual} role="presentation" />
    return visual;
  }

  render() {
    const { context, router, modal = undefined } = this.props;
    const { culture } = context;

    const routes = [
      ...journeyRoutes(router, culture),
      ...defaultRoutes(router, culture)
    ].map((routeProps, index) => (
      <Route key={`route-${index}`} {...routeProps} />
    ));

    return (
      <div id="main" className="main">
        <div id="outdated" />
        <Page showHorizon>
          <Switch>{routes}</Switch>
        </Page>
        <Modal
          data-testid={modal.testIdentifier}
          portalContainer={document.getElementById("main") || document.body}
          title={modal.title}
          body={this.renderModalBody()}
          visual={this.renderVisual(modal.visual)}
          confirmText={modal.confirmText}
          closeText={modal.closeText ?? t("close_button")}
          onClose={modal.onClose}
          onConfirm={modal.onConfirm}
          destructiveConfirm={modal.destructiveConfirm}
          hideCloseIcon={modal.hideCloseIcon}
          hideCloseButton={modal.hideCloseButton}
          role={modal.role}
          isOpen={modal.isOpen} />
      </div>
    );
  }
}

function mapStateToProps(state: RootState): App.StateProps {
  return {
    context: state.context,
    router: state.router,
    auth: state.auth,
    modal: state.modal
  };
}

function mapDispatchToProps(dispatch: Dispatch): App.ActionProps {
  return {
    contextactions: bindActionCreators(ContextActions, dispatch),
    authActions: bindActionCreators(AuthActions, dispatch),
    modalActions: bindActionCreators(ModalActions, dispatch)
  };
}

export default hot(module)(connect(mapStateToProps, mapDispatchToProps)(App));
