import sanitizeHtml from 'sanitize-html';

function addPopupWarningForExternalLink(el: HTMLAnchorElement, onPopupWarning: (href:string) => void) {

    if(!el.href.toLowerCase().includes("lego.com")) {
        el.onclick = (e: MouseEvent) => {
            e.preventDefault();
            onPopupWarning(el.href);
        };
        return el;
    }
    return el;
}

function warningForExternalLink(content: HTMLElement, onPopupWarning: (href:string) => void) {
    //Locate all links
    const links = Array.from(content.getElementsByTagName("a"));

    //Find all links that should be transformed
    for (var i = 0; i < links.length; i++) {
        links[i] = addPopupWarningForExternalLink(links[i], onPopupWarning);
    }
    return content;
}

function sanitizeContent(rawContentString: string) {
    const html = document.createElement("div") as HTMLElement;
    html.classList.add("text");
    html.classList.add("formatted-text");
    const cleanContent = sanitizeHtml(rawContentString ?? "", {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'img' ])
    });
    html.innerHTML = cleanContent;
    return html;
}

function prefixRelativeLinksWithDomain(html: HTMLElement, rootDomain: string) {
    //Locate all links
    const links = html.getElementsByTagName("a");

    //Find all links that should be changed
    for (let linkElement of links as any){
        const href = linkElement.href;

        if(href) {
            const linkOuterHtml = linkElement.outerHTML;
            const linkIsRelative = linkOuterHtml.includes(`"${linkElement.pathname}"`);

            if(linkIsRelative) {
                linkElement.href = `${rootDomain}${linkElement.pathname}`;
            }
        }
    }

    return html;
}

function replaceLinksWithText(content: HTMLElement, appDomain: string) {
    //Locate all links
    const links = content.getElementsByTagName("a");
    let elementsToTransform: HTMLAnchorElement[] = [];

    //Find all links that should be transformed
    for (let linkElement of links as any){
        const href = linkElement.href;

        if(href) {
            const linkIsLegit = href.includes("://");
            const linkIsAnchor = linkElement.hash ? true : false;
            const linkIsLocal = href.includes(appDomain);

            const shouldTransform = ((linkIsLegit && linkIsLocal === false) || (linkIsAnchor === false));

            if(shouldTransform) {
                elementsToTransform.push(linkElement);
            }
        }
    }

    //Transform the links by using DOM manipulation since string replace will fail for parsed inner html content.
    elementsToTransform.forEach(linkElement => {
        // We are checking here if the innerHTML is equal to href because we dont want to write the href twice, its confusing to the user.
        let contentAfter = `${linkElement.innerHTML} (${linkElement.href})`;
        if(linkElement.innerHTML === sanitizeHtml(linkElement.href)) {
            contentAfter = `${linkElement.href}`;
        }


        const newContent = document.createElement("span");
        newContent.innerHTML = sanitizeHtml(contentAfter);
        linkElement.parentNode.replaceChild(newContent, linkElement);
    });

    return content;
}

export default class FormatText {
    stripLinks: boolean;
    showWarningOnExternalLinks: boolean;
    appDomain: string;
    rootDomain: string;
    onPopupWarning: (href:string) => void;
    constructor(
        stripLinks: boolean,
        showWarningOnExternalLinks: boolean,
        appDomain: string,
        rootDomain: string,
        onPopupWarning: (href:string) => void = () => {}
    ) {
        this.stripLinks = stripLinks;
        this.rootDomain = rootDomain;
        this.onPopupWarning = onPopupWarning;
        this.showWarningOnExternalLinks = showWarningOnExternalLinks;
        this.appDomain = appDomain
                .replace("http://", "")
                .replace("https://", "")
                .replace("www.", "");

        return this;
    }

    Apply(text: string) : HTMLElement {
        return this.ApplyElement(sanitizeContent(text))
    }

    ApplyElement(content: HTMLElement) {
        content = prefixRelativeLinksWithDomain(content, this.rootDomain);

        this.showWarningOnExternalLinks && (content = warningForExternalLink(content, this.onPopupWarning));
        this.stripLinks && (content = replaceLinksWithText(content, this.appDomain));

        return content;
    }
}
