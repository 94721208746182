import { memo } from "react";
import { useTranslation, useModal } from "scripts/hooks";
import { Button } from "@lego/lego-account-ui";
import { PolicyModal } from "./PolicyModal";

namespace Legals {
  export type Props = {
    readonly context: ContextState;
  };
}

const Legals = memo((props: Legals.Props): JSX.Element => {
  const { triggerModal, dismissModal } = useModal();
  const { t } = useTranslation(["LegalsComponent"]);

  const {
    context: {
      hideheader,
      appContext,
    }
  } = props;

  const openPolicyModal = async () => {
    triggerModal({
      testIdentifier: "Privacy",
      body: <PolicyModal context={props.context} variant="privacy-policy" />,
      hideCloseIcon: false,
      hideCloseButton: false,
      onClose: async () => dismissModal()
    });
  }

  const openCookieModal = async () => {
    triggerModal({
      testIdentifier: "Cookie",
      body: <PolicyModal context={props.context} variant="cookie-policy" />,
      hideCloseIcon: false,
      hideCloseButton: false,
      onClose: async () => dismissModal()
    });
  }
  if (appContext || hideheader) {
    return (
      <>
        <Button
          onPress={openPolicyModal}
          variant="link"
          aria-haspopup="true"
          data-testid="legal-privacy-policy"
        >
          {t("LegalsComponent:privacy_policy_link_text")}
        </Button>
        |
        <Button
          onPress={openCookieModal}
          variant="link"
          aria-haspopup="true"
          data-testid="legal-cookie-policy"
        >
          {t("LegalsComponent:cookie_policy_link_text")}
        </Button>
      </>
    );
  } else {
    return null;
  }
});

export default Legals
