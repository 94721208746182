/* eslint-disable import/prefer-default-export */
import React from "react";
import { Headline, Spinner } from "@lego/lego-account-ui";
import { type PolicyVariant, usePolicy } from "@lego/lego-account-core";
import { SafeContent } from "./SafeContent";

type PolicyModalyProps = {
  context: ContextState;
  variant: PolicyVariant;
};

export const PolicyModal = ({ context, variant }: PolicyModalyProps) => {
  const { isLoading, policy } = usePolicy({
    audience: "adult", // For VPC we apparently always get the adult policy
    baseUrl: context.serviceEndpoints.wwwRootDomain,
    locale: context.culture,
    variant
  });

  if (isLoading) return <Spinner />;

  return (
    <>
      <Headline variant="h2">{policy.title}</Headline>
      <SafeContent content={policy.content} context={context} />
    </>
  );
};
