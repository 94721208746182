import { ThunkAction } from "redux-thunk";
import { TrackingService } from "@library";
import { AuthActions, AuthFlowType, Reason } from "scripts/actions/authActions";
import { RestResponse } from "scripts/services/restClient";
import { ModalActions, NavigationRoute, RouteActions } from "scripts/actions";
import i18n, { t } from "scripts/utils/i18n";

export interface ErrorsContent {
  title: string;
  header: string;
  text: string;
  button_label: string;
  image: string;
}

export enum ErrorBehaviorType {
  ERROR_BEHAVIOR_RETRY = "RETRY",
  ERROR_BEHAVIOR_RETURN_TO_CLIENT = "RETURN_TO_CLIENT",
  ERROR_BEHAVIOR_GO_BACK = "GO_BACK",
  ERROR_BEHAVIOR_REAUTH = "REAUTH",
  ERROR_BEHAVIOR_GOTO_PAGE = "GOTO_PAGE",
  ERROR_BEHAVIOR_DISMISS_ERROR = "DISMISS_ERROR"
}

export enum ErrorTypes {
  LOGIN_REQUIRED = "LOGIN_REQUIRED",
  NETWORK_ERROR = "NETWORK_ERROR",
  GENERIC_ERROR = "GENERIC_ERROR",
  SERVER_ERROR = "SERVER_ERROR",
  BAD_REQUEST = "BAD_REQUEST",
  CLIENT_IS_NOT_VALID = "CLIENT_IS_NOT_VALID",
  PARENT_ACCOUNT_NOT_FOUND = "PARENT_ACCOUNT_NOT_FOUND",
  VALIDATE_PARENT_NOT_AN_ADULT = "VALIDATE_PARENT_NOT_AN_ADULT",
  VALIDATE_PARENT_NOT_THE_CHILD_PARENT = "VALIDATE_PARENT_NOT_THE_CHILD_PARENT",
  VPC_TOKEN_INVALID_OR_EXPIRED = "VPC_TOKEN_INVALID_OR_EXPIRED",
  ACCESS_TOKEN_INVALID_OR_EXPIRED = "ACCESS_TOKEN_INVALID_OR_EXPIRED",
  PAGE_NOT_FOUND = "PAGE_NOT_FOUND",
  CREDITCARD_REFUSED = "[VERIFICATION_ERROR]CREDITCARD_REFUSED",
  VPC_NO_ACCESS = "NO_ACCESS",
  CARD_TYPE_NOT_ACCEPTED = "[VERIFICATION_ERROR]CARD_TYPE_NOT_ACCEPTED",
  CARD_SERVER_ERROR = "[VERIFICATION_ERROR]CARD_SERVICE_SERVER_ERROR",
  ID_SERVER_ERROR = "[VERIFICATION_ERROR]ID_SERVER_ERROR",
  ID_FAIL_VERIFICATION = "[VERIFICATION_ERROR]ID_FAIL_VERIFICATION",
  USER_IS_SAME_AS_REQUESTING_VPC = "USER_IS_SAME_AS_REQUESTING_VPC",

  //Jumio tehnical errorCode - https://github.com/Jumio/implementation-guides/blob/master/netverify/netverify-web-v4.md#after-the-user-journey
  ERROR_OCCURRED_ON_OUR_SERVER = "[VERIFICATION_ERROR]ERROR_OCCURRED_ON_OUR_SERVER", //9100
  AUTHORIZATION_TOKEN_MISSING_INVALID_OR_EXPIRED = "[VERIFICATION_ERROR]AUTHORIZATION_TOKEN_MISSING_INVALID_OR_EXPIRED", //9200
  SESSION_EXPIRED_AFTER_THE_USER_JOURNEY_STARTED = "[VERIFICATION_ERROR]SESSION_EXPIRED_AFTER_THE_USER_JOURNEY_STARTED", //9210
  ERROR_OCCURRED_TRANSMITTING_IMAGE_TO_OUR_SERVER = "[VERIFICATION_ERROR]ERROR_OCCURRED_TRANSMITTING_IMAGE_TO_OUR_SERVER", //9300
  ERROR_OCCURRED_DURING_VERIFICATION_STEP = "[VERIFICATION_ERROR]ERROR_OCCURRED_DURING_VERIFICATION_STEP", //9400
  USER_HAS_NO_NETWORK_CONNECTION = "[VERIFICATION_ERROR]USER_HAS_NO_NETWORK_CONNECTION", //9800
  UNEXPECTED_ERROR_OCCURRED_IN_THE_CLIENT = "[VERIFICATION_ERROR]UNEXPECTED_ERROR_OCCURRED_IN_THE_CLIENT", //9801
  PROBLEM_WHILE_COMMUNICATING_WITH_OUR_SERVER = "[VERIFICATION_ERROR]PROBLEM_WHILE_COMMUNICATING_WITH_OUR_SERVER", //9810
  FILE_UPLOAD_NOT_ENABLED_AND_CAMERA_UNAVAILABLE = "[VERIFICATION_ERROR]FILE_UPLOAD_NOT_ENABLED_AND_CAMERA_UNAVAILABLE", //9820
  THE_3D_LIVENESS_FACE_CAPTURE_PROCESS_FAILED_ISSUE_WITH_IPROOV = "[VERIFICATION_ERROR]THE_3D_LIVENESS_FACE_CAPTURE_PROCESS_FAILED_ISSUE_WITH_IPROOV", //9821
  BROWSER_DOES_NOT_SUPPORT_CAMERA = "[VERIFICATION_ERROR]BROWSER_DOES_NOT_SUPPORT_CAMERA", //9822
  NO_ACCEPTABLE_SUBMISSION_IN_3_ATTEMPTS = "[VERIFICATION_ERROR]NO_ACCEPTABLE_SUBMISSION_IN_3_ATTEMPTS", //9835
  TECHNICAL_ID_FAIL_VERIFICATION = "[VERIFICATION_ERROR]TECHNICAL_ID_FAIL_VERIFICATION", // other errorCode than above
  VERIFICATION_NOT_READABLE_ID = "Not_Readable_Id",
  VERIFICATION_FACIAL_MATCH_NOT_POSSIBLE = "Facial_Match_Not_Possible",
  VERIFICATION_FACIAL_NOT_A_MATCH = "Facial_Not_A_Match",
  VERIFICATION_UNSUPPORTED_ID_TYPE = "Unsupported_ID_Type",
  VERIFICATION_UNSUPPORTED_ID_COUNTRY = "Unsupported_ID_Country",
  VERIFICATION_NO_ID_UPLOADED = "No_Id_Uploaded",
  VERIFICATION_FRAUD = "Fraud",
  VERIFICATION_TOO_MANY_FAILED_ATTEMPTS = "Too_Many_Failed_Attempts",

  LINKED_CHILD_CONSENTS_NOT_SAVED = "LINKED_CHILD_CONSENTS_NOT_SAVED",
  TEMPORARY_LINK_TOKEN_MISSING = "TEMPORARY_LINK_TOKEN_MISSING",
  ACCOUNT_REQUIRED = "ACCOUNT_REQUIRED",
  ACCOUNT_IS_ADULT_IN_CHILD_CONTEXT = "ACCOUNT_IS_ADULT_IN_CHILD_CONTEXT",
  NICKNAME_IN_USE = "NICKNAME_IN_USE",
  NICKNAME_USER_NOT_FOUND = "NICKNAME_USER_NOT_FOUND",

  USER_LINKED_TO_DIFFERENT_ACCOUNT = "user_linked_to_different_account",
  USER_IS_ADULT = "user_is_adult",
  INVALID_USER = "invalid_user",
  TEMPORARY_LINK_TOKEN_EXPIRED = "token_expired",
  TEMPORARY_LINK_SESSION_EXPIRED = "session_expired",
  TEMPORARY_LINK_TOKEN_INVALID = "invalid_session",

  JOURNEY_CHILD_UPGRADE_ACCESS_DENIED = "access_denied_invalidToken",
  CLIENT_ID_NOT_PROVIDED = "Client_id_not_provided",

  BANNED_EMAIL = "email_not_allowed",
  TOO_MANY_REQUESTS = "too_many_requests",
  VERIFICATION_FAILURE = "VERIFICATION_FAILURE"
}

const legoIDFullAccountAssets = require.context(
  "../../assets/LEGOIDFullAccount",
  false
);

export namespace ErrorActions {

  export const createReturnToClientBehavior = (
    reason = Reason.ERROR
  ): ErrorBehavior => ({
    action: ErrorBehaviorType.ERROR_BEHAVIOR_RETURN_TO_CLIENT,
    reason
  });

  export const createGoBackToClientBehavior = (
    reason = Reason.ERROR
  ): ErrorBehavior => ({
    action: ErrorBehaviorType.ERROR_BEHAVIOR_RETURN_TO_CLIENT,
    reason
  });

  export const createGoBackBehavior = (): ErrorBehavior => ({
    action: ErrorBehaviorType.ERROR_BEHAVIOR_GO_BACK
  });

  export const createDismissBehavior = (): ErrorBehavior => ({
    action: ErrorBehaviorType.ERROR_BEHAVIOR_DISMISS_ERROR
  });

  export const createReauthBehavior = (
    returnurl: string,
    flow: AuthFlowType
  ): ErrorBehavior => {
    if (!returnurl) throw "Missing returnurl in error behavior";
    return {
      action: ErrorBehaviorType.ERROR_BEHAVIOR_REAUTH,
      returnurl,
      flow
    };
  };

  const mapImages = (baseImageName: string) => {
    return legoIDFullAccountAssets.keys().filter((path) => {
      return new RegExp(`\.\/${baseImageName}\@[1-4]x.png`).test(path);
    });
  };

  export const createGotoPageBehavior = (gotourl: string): ErrorBehavior => {
    if (!gotourl) throw "Missing gotourl in error behavior";
    return {
      action: ErrorBehaviorType.ERROR_BEHAVIOR_GOTO_PAGE,
      gotourl
    };
  };

  export const createGotoLegoComBehavior = (): ErrorBehavior => {
    return {
      action: ErrorBehaviorType.ERROR_BEHAVIOR_GOTO_PAGE,
      gotourl: "https://www.lego.com"
    };
  };

  export const createRetryBehavior = (): ErrorBehavior => ({
    action: ErrorBehaviorType.ERROR_BEHAVIOR_RETRY
  });

  const getAdultAuthorizeFlow = (): AuthFlowType => {
    return "flow:register";
  };

  export interface ErrorObject {
    message: string;
  }

  const getErrorMessage = (error: string | ErrorObject) => {
    let errorMessage = typeof error === "string" ? error : error.message;
    if (errorMessage && errorMessage.indexOf("Error: ") >= 0) {
      errorMessage = errorMessage.split("Error: ")[1];
    }
    return errorMessage;
  };

  const getFallbackErrorContent = (): ErrorsContent => {
    return {
      button_label: "Retry",
      header: "An Error Occurred",
      image: "error-internet-issue-policeman-wire",
      text: "Unfortunately, something seems to have gone wrong with our LEGO® Account system. We have logged the error and have already begun investigating it. However, the error may be temporary, so you are welcome to try again.",
      title: "GENERIC_ERROR"
    };
  };

  const getCurrentErrorContent = (currentError: string): ErrorsContent => {
    const fallbackError = ErrorTypes.GENERIC_ERROR.toUpperCase();
    try {
      const contentErrors = <ErrorsContent[]>(<unknown>t("Errors:entries"));
      if (contentErrors && contentErrors.length > 0) {
        return (
          contentErrors.find(
            (e: ErrorsContent) => e?.title?.toUpperCase() === currentError
          ) ||
          contentErrors.find(
            (e: ErrorsContent) => e?.title?.toUpperCase() === fallbackError
          )
        );
      } else {
        return getFallbackErrorContent();
      }
    } catch (error) {
      return getFallbackErrorContent();
    }
  };

  export const ErrorAction =
    ({
      error,
      behavior = undefined
    }: {
      error: string | ErrorObject;
      behavior?: ErrorBehavior;
    }): ThunkAction<void, RootState, void, null> =>
    async (dispatch, getState) => {
      let errorBehavior: ErrorBehavior = behavior;

      const errorMessage = getErrorMessage(error);

      console.log(`Error message: ${errorMessage}`);

      await i18n.loadNamespaces(["Errors"]);
      const currentErrorContent: ErrorsContent = getCurrentErrorContent(
        errorMessage.toUpperCase()
      );

      const {
        header: heading,
        text: details,
        button_label: primaryButtonLabel
      } = currentErrorContent;

      let image: string[] = [
        legoIDFullAccountAssets("./error-internet-issue-policeman-wire@1x.png"),
        legoIDFullAccountAssets("./error-internet-issue-policeman-wire@2x.png"),
        legoIDFullAccountAssets("./error-internet-issue-policeman-wire@3x.png")
      ];

      if (currentErrorContent?.image) {
        image = mapImages(currentErrorContent.image).map((img) =>
          legoIDFullAccountAssets(img)
        );
      }
      if (!behavior) {
        switch (errorMessage) {
          case ErrorTypes.PARENT_ACCOUNT_NOT_FOUND:
            return;

          case ErrorTypes.CLIENT_ID_NOT_PROVIDED:
          case ErrorTypes.CLIENT_IS_NOT_VALID:
            errorBehavior = createGotoLegoComBehavior();
            break;

          case ErrorTypes.ACCOUNT_IS_ADULT_IN_CHILD_CONTEXT:
          case ErrorTypes.ACCOUNT_REQUIRED:
          case ErrorTypes.BAD_REQUEST:
          case ErrorTypes.LOGIN_REQUIRED:
          case ErrorTypes.NICKNAME_USER_NOT_FOUND:
          case ErrorTypes.VPC_TOKEN_INVALID_OR_EXPIRED:
            errorBehavior = createReturnToClientBehavior();
            break;

          case ErrorTypes.PAGE_NOT_FOUND:
            errorBehavior = createGoBackBehavior();
            break;

          case ErrorTypes.VALIDATE_PARENT_NOT_AN_ADULT:
            errorBehavior = createReauthBehavior(
              await dispatch(
                RouteActions.getRoute(NavigationRoute.LINK_CONSENT)
              ),
              getAdultAuthorizeFlow()
            );
            break;

          case ErrorTypes.USER_LINKED_TO_DIFFERENT_ACCOUNT:
          case ErrorTypes.VALIDATE_PARENT_NOT_THE_CHILD_PARENT:
            errorBehavior = createReauthBehavior(
              await dispatch(
                RouteActions.getRoute(NavigationRoute.VERIFY_OPTIONS)
              ),
              getAdultAuthorizeFlow()
            );
            break;

          case ErrorTypes.VPC_NO_ACCESS:
            errorBehavior = createGoBackToClientBehavior();
            break;

          case ErrorTypes.AUTHORIZATION_TOKEN_MISSING_INVALID_OR_EXPIRED:
          case ErrorTypes.BROWSER_DOES_NOT_SUPPORT_CAMERA:
          case ErrorTypes.ERROR_OCCURRED_DURING_VERIFICATION_STEP:
          case ErrorTypes.ERROR_OCCURRED_ON_OUR_SERVER:
          case ErrorTypes.ERROR_OCCURRED_TRANSMITTING_IMAGE_TO_OUR_SERVER:
          case ErrorTypes.FILE_UPLOAD_NOT_ENABLED_AND_CAMERA_UNAVAILABLE:
          case ErrorTypes.ID_FAIL_VERIFICATION:
          case ErrorTypes.ID_SERVER_ERROR:
          case ErrorTypes.NO_ACCEPTABLE_SUBMISSION_IN_3_ATTEMPTS:
          case ErrorTypes.PROBLEM_WHILE_COMMUNICATING_WITH_OUR_SERVER:
          case ErrorTypes.SESSION_EXPIRED_AFTER_THE_USER_JOURNEY_STARTED:
          case ErrorTypes.TECHNICAL_ID_FAIL_VERIFICATION:
          case ErrorTypes.THE_3D_LIVENESS_FACE_CAPTURE_PROCESS_FAILED_ISSUE_WITH_IPROOV:
          case ErrorTypes.UNEXPECTED_ERROR_OCCURRED_IN_THE_CLIENT:
          case ErrorTypes.USER_HAS_NO_NETWORK_CONNECTION:
          case ErrorTypes.VERIFICATION_FACIAL_MATCH_NOT_POSSIBLE:
          case ErrorTypes.VERIFICATION_FACIAL_NOT_A_MATCH:
          case ErrorTypes.VERIFICATION_FAILURE:
          case ErrorTypes.VERIFICATION_FRAUD:
          case ErrorTypes.VERIFICATION_NO_ID_UPLOADED:
          case ErrorTypes.VERIFICATION_NOT_READABLE_ID:
          case ErrorTypes.VERIFICATION_TOO_MANY_FAILED_ATTEMPTS:
          case ErrorTypes.VERIFICATION_UNSUPPORTED_ID_COUNTRY:
          case ErrorTypes.VERIFICATION_UNSUPPORTED_ID_TYPE:
            errorBehavior = createGotoPageBehavior(
              await dispatch(
                RouteActions.getRoute(NavigationRoute.VERIFY_OPTIONS)
              )
            );
            break;

          case ErrorTypes.BANNED_EMAIL:
          case ErrorTypes.LINKED_CHILD_CONSENTS_NOT_SAVED:
          case ErrorTypes.NICKNAME_IN_USE:
            errorBehavior = createDismissBehavior();
            break;

          case ErrorTypes.TOO_MANY_REQUESTS:
            errorBehavior = createDismissBehavior();
            break;

          case ErrorTypes.JOURNEY_CHILD_UPGRADE_ACCESS_DENIED:
          case ErrorTypes.TEMPORARY_LINK_TOKEN_EXPIRED:
          case ErrorTypes.TEMPORARY_LINK_TOKEN_INVALID:
          case ErrorTypes.TEMPORARY_LINK_TOKEN_MISSING:
            errorBehavior = createGotoLegoComBehavior();
            break;

          case ErrorTypes.INVALID_USER:
          case ErrorTypes.USER_IS_ADULT:
            errorBehavior = createRetryBehavior();
            break;

          case ErrorTypes.GENERIC_ERROR:
          case ErrorTypes.SERVER_ERROR:
          default:
            errorBehavior = createRetryBehavior();
            break;
        }
      }
      TrackingService.trackError(errorMessage);

      const handleBehavior = () => {
        switch (errorBehavior.action) {
          case ErrorBehaviorType.ERROR_BEHAVIOR_RETURN_TO_CLIENT:
            dispatch(AuthActions.ReturnToClient(false));
            break;
          case ErrorBehaviorType.ERROR_BEHAVIOR_REAUTH:
            dispatch(
              AuthActions.DoRedirectLogin(
                errorBehavior.returnurl,
                errorBehavior.flow
              )
            );
            break;
          case ErrorBehaviorType.ERROR_BEHAVIOR_GOTO_PAGE:
            document.location.href = errorBehavior.gotourl;
            return new Promise<void>(() => { });
          case ErrorBehaviorType.ERROR_BEHAVIOR_RETRY:
          default:
            document.location.reload();
            return new Promise<void>(() => { });
        }
      };

      dispatch(ModalActions.trigger({
        title: heading,
        testIdentifier: errorMessage,
        body: details,
        richBody: true,
        visual: image[0],
        confirmText: primaryButtonLabel,
        onConfirm: handleBehavior,
        hideCloseButton: true,
        hideCloseIcon: true
      }));

    };

  export const ErrorResponse =
    (response: RestResponse<any>): ThunkAction<void, RootState, void, null> =>
    (dispatch) => {
      //TODO: Some better generic handling goes here for RestRequest errors
      let errorCode = "Response was null or undefined";
      if (response) {
        errorCode =
          response.errors && response.errors.length > 0
            ? response?.errors[0]
            : null;
        if (!errorCode) {
          switch (response.status) {
            case 401: // UNAUTHORIZED
              errorCode = ErrorTypes.LOGIN_REQUIRED;
              break;
            case 429: // Too Many Requests
              errorCode = ErrorTypes.TOO_MANY_REQUESTS;
              break;
            default:
              errorCode = response.status.toString();
              console.error(`Error code/status "${errorCode}" not handled.`);
              break;
          }
        }
      }
      dispatch(ErrorAction({ error: errorCode }));
      return;
    };
}

export type ErrorActions = typeof ErrorActions;
