import "core-js/stable";
import "regenerator-runtime/runtime";
import "whatwg-fetch";
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { configureStore } from "./stores/configureStore";
import App from "./app";
import getCurrentStateFromQueryParameters from "./stateHelper";
import { Loader, TrackingService } from "@library";
import { injectGNSScriptTag, selfHost } from "./utils";
import userManager from "./services/userManager";
import outdatedBrowser from "./lib/outdatedBrowser";
import "../styles/main.less";
import { PersistGate } from "redux-persist/integration/react";
import { FlowType } from "./types/storeTypes";
import { ApplicationConfig, getAppConfig } from "./utils/applicationConfig";
import i18n, { initializeI18N, t } from "scripts/utils/i18n";
import { ThemeProvider, ThemeProviderProps } from "@lego/lego-account-ui";
import { initTracker } from "./utils/tracking";

declare global {
  interface Window {
    legotrackman: Function;
  }
}

const disableTracking = true;

window.legotrackman = function (
  basicTracking: any,
  accountTracking: any,
  mediaTracking: any
) {
  TrackingService.init(
    basicTracking,
    accountTracking,
    mediaTracking,
    disableTracking
  );
};

// Spawn app
const app = document.querySelector(".app");
ReactDOM.render(<Loader ariaLabel={t("loading_text")} />, app);

async function bootStrapContents(
  envConfigP: ApplicationConfig,
  initialState: Partial<ContextState>
) {
  const {
    adultexperience,
    appContext,
    clientid,
    clientIdWasProvidedByClient,
    feature,
    hideCloseButton,
    hideheader,
    isott,
    returnurl,
    returnUrlWasProvidedByClient,
    state,
    theme
  } = initialState;
  const { identityClientId, overrideHideHeader, serviceEndpoints } = envConfigP;
  const { culture } = initialState;
  const environment = document.location.hostname.includes(".dev.")
    ? "dev"
    : document.location.hostname.includes("webqa")
      ? "qa"
      : "live";
  const release = document.querySelector('meta[name="version"]').getAttribute("content")

  const domainUserId: string = await initTracker(serviceEndpoints.trackingService, serviceEndpoints.trackingServiceTimeout, environment, release, initialState.culture)

  if (!disableTracking) TrackingService.setCulture(culture);

  const lang = culture.substr(0, 2);

  const history = createBrowserHistory();
  const { store, persistor } = configureStore(
    {
      context: {
        adultexperience,
        appContext,
        clientid,
        clientIdWasProvidedByClient,
        culture,
        feature,
        hideCloseButton,
        hideheader: hideheader || overrideHideHeader,
        isott,
        lang,
        returnurl,
        returnUrlWasProvidedByClient,
        selfHost,
        serviceEndpoints,
        state,
        theme,
        vpcClientid: identityClientId,
        journey: {
          flowType: FlowType.SameDevice,
          verifiedEmailToken: undefined,
          securityId: undefined
        }
      }
    },
    history
  );
  //WACG - Ensure that scrollable region has keyboard access
  document.getElementsByTagName("body")[0].setAttribute("tabindex", "-1");

  if (culture && document.documentElement.getAttribute("lang") !== culture) {
    document.documentElement.setAttribute("lang", culture);
  }
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <PersistGate
          loading={<Loader ariaLabel={t("loading_text")} />}
          persistor={persistor}
        >
          <ThemeProvider theme={theme as ThemeProviderProps['theme']}>
            <React.Suspense fallback={<Loader ariaLabel={t("loading_text")} />}>
              <App history={history} />
            </React.Suspense>
          </ThemeProvider>
        </PersistGate>
      </ConnectedRouter>
    </Provider>,
    app
  );

  if (!appContext && !hideheader && !overrideHideHeader) {
    injectGNSScriptTag(serviceEndpoints.rootDomain, culture, "VPC");
  }

  if (module.hot) {
    module.hot.accept();
  }
}

async function startLoad() {
  const appConfig = getAppConfig();

  // Set the identityClientId globally to make it accessible for usermanager
  userManager.init({
    clientId: appConfig?.identityClientId,
    authority: appConfig?.userManager?.authority,
    redirectUri: appConfig?.userManager?.redirectUri,
    silentRedirectUri: appConfig?.userManager?.silentRedirectUri,
    postLogoutRedirectUri: appConfig?.userManager?.postLogoutRedirectUri,
    scopes: appConfig?.userManager?.scopes || []
  });

  if (!disableTracking)
    TrackingService.setClientID(appConfig?.identityClientId || "");

  const initialState = getCurrentStateFromQueryParameters(
    appConfig?.identityClientId
  );

  initializeI18N(initialState.culture);
  await i18n.loadNamespaces("Generic");
  await bootStrapContents(appConfig, initialState);
}

const outdatedBrowserOptions = {
  browserSupport: {
    Chrome: 90,
    Edge: 92,
    Safari: 10.1,
    "Mobile Safari": 10.3,
    Firefox: 78,
    Opera: 78
  },
  requireChromeOnAndroid: false,
  isUnknownBrowserOK: true
};

document.addEventListener("DOMContentLoaded", function () {
  outdatedBrowser(outdatedBrowserOptions);
});

startLoad();
