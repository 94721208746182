import { ContextActionTypes } from "scripts/actions";
import { FlowType } from "scripts/types/storeTypes";

const initialState: ContextState = {
  clientid: "",
  returnurl: "",
  appContext: false,
  hideheader: false,
  hideCloseButton: false,
  adultexperience: false,
  culture: "",
  lang: "",
  serviceEndpoints: null,
  state: "",
  theme: "",
  returnUrlWasProvidedByClient: false,
  clientIdWasProvidedByClient: false,
  selfHost: "",
  isott: false,
  vpcClientid: "",
  feature: 0,
  journey: {
    flowType: FlowType.SameDevice,
    verifiedEmailToken: undefined,
    securityId: undefined
  }
};

export function ContextReducer(
  state = initialState,
  action: ContextAction
): ContextState {
  switch (action.type) {
    case ContextActionTypes.QUERY_CONTEXT_CHANGED:
      return {
        ...state,
        returnurl: action.returnurl,
        clientid: action.clientid,
        state: action.state,
        adultexperience: action.adultexperience,
        appContext: action.appContext
      };
    case ContextActionTypes.CLIENT_CONTEXT_CHANGED:
      return {
        ...state,
        returnurl: action.returnurl ?? state.returnurl,
        clientid: action.clientid ?? state.clientid,
        state: action.state ?? state.state,
        theme: action.theme ?? state.theme
      };
    case ContextActionTypes.JOURNEY_CONTEXT_CHANGED:
      return {
        ...state,
        journey: action.journey
      };
    default:
      return state;
  }
}
